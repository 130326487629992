        @import './_SiteCore.scss';
        html,
        body {
            position: relative
        }
        
        .homeWrap {
            position: fixed;
            top: 0px;
            width: 100%;
            height: 1000px;
            overflow: hidden;
            @media (min-width: 768px) {
                top: 40px;
            }
            .outerWrap {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 500px;
                overflow: hidden;
                margin: 0;
                @media (min-width: 320px) {
                    height: 450px;
                }
                @media (min-width: 345px) {
                    height: 500px;
                }
                @media (min-width: 411px) {
                    height: 500px;
                }
                @media (min-width: 640px) {
                    height: 450px;
                }
                @media (min-width: 728px) {
                    height: 400px;
                }
                @media (min-width: 768px) {
                    height: 450px;
                }
                @media (min-width: 800px) {
                    height: 450px;
                }
                @media (min-width: 960px) {
                    height: 410px;
                }
                @media (min-width: 1024px) {
                    height: 450px;
                }
                /* iphone 5 horz */
                @media (min-width: 568px) and (max-height: 320px) {
                    height: 280px;
                }
                /* iphone 6/7/8 horz */
                @media (min-width: 667px) and (max-height: 375px) {
                    height: 344px;
                }
                /* iphone 6/7/8 plus horz */
                @media (min-width: 736px) and (max-height: 414px) {
                    height: 334px;
                }
                /* pixel 2  horz */
                @media (min-width: 731px) and (max-height:411px) {
                    height: 342px;
                }
                /* pixel 2 XL horz */
                @media (min-width: 823px) and (max-height:411px) {
                    height: 300px;
                }
                /* iphone X*/
                @media (min-width: 812px) and (max-height:375px) {
                    height: 300px;
                }
            }
            .homepage-bg {
                background-image: url("/img/perugia-trees-16-9.jpg");
                background-size: cover;
                background-position: center bottom;
                background-repeat: no-repeat;
            }
            .grid-paper-bg {
                opacity: .12;
                background-color: #556;
                background-image: linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a), linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a);
                background-size: 80px 140px;
                background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
            }
            .content-slide {
                position: absolute;
                width: 100%;
                height: 500px;
                overflow: hidden;
                @media (min-width: 320px) {
                    height: 464px;
                }
                @media (min-width: 345px) {
                    height: 500px;
                }
                @media (min-width: 411px) {
                    height: 480px;
                }
                @media (min-width: 540px) {
                    height: 430px;
                }
                @media (min-width: 640px) {
                    height: 440px;
                }
                @media (min-width: 728px) {
                    height: 380px;
                }
                @media (min-width: 768px) {
                    height: 430px;
                }
                @media (min-width: 800px) {
                    height: 440px;
                }
                @media (min-width: 1024px) {
                    height: 450px;
                }
                /* iphone 5 horz */
                @media (min-width: 568px) and (max-height: 320px) {
                    height: 280px;
                }
                /* iphone 6/7/8 horz */
                @media (min-width: 667px) and (max-height: 375px) {
                    height: 352px;
                }
                /* pixel 2  horz */
                @media (min-width: 728px) and (max-height:411px) {
                    height: 342px;
                }
                /* pixel 2 XL horz */
                @media (min-width: 823px) and (max-height:411px) {
                    height: 370px;
                }
                /* iphone X*/
                @media (min-width: 812px) and (max-height:375px) {
                    height: 352px;
                }
            }
        }
        
        .spinner {
            position: relative;
            top: 50px;
            left: 200px;
            z-index: 20;
            @media (min-width: 320px) {
                top: 100px;
                left: 140px;
            }
            @media (min-width: 345px) {}
            @media (min-width: 411px) {}
            @media (min-width: 520px) {}
            @media (min-width: 640px) {
                top: 100px;
                left: 360px;
            }
            @media (min-width: 640px) {
                top: 120px;
                left: 360px;
            }
            @media (min-width: 728px) {
                top: 100px;
                left: 360px;
            }
            @media (min-width: 768px) {
                top: 100px;
                left: 460px;
            }
            @media (min-width: 800px) {
                top: 70px;
                left: 470px;
            }
            @media (min-width: 1024px) {
                top: 50px;
                left: 620px;
            }
            @media (min-width: 1200px) {
                top: 30px;
                left: 720px;
            }
            /* iphone 5 horz */
            @media (min-width: 568px) and (max-height: 320px) {
                top: 80px;
                left: 350px;
            }
            /* iphone 6/7/8 horz */
            @media (min-width: 667px) and (max-height: 375px) {
                top: 80px;
                left: 350px;
            }
            /* pixel 2  horz */
            @media (min-width: 728px) and (max-height:411px) {
                top: 70px;
                left: 350px;
            }
            /* pixel 2 XL horz */
            @media (min-width: 823px) and (max-height:411px) {
                top: 0px;
                left: 370px;
            }
            /* iphone X*/
            @media (min-width: 812px) and (max-height:375px) {
                top: -30px;
                left: 400px;
            }
            .ring {
                width: 160px;
                height: 160px;
                @media (min-width: 640px) {
                    width: 180px;
                    height: 180px;
                }
                @media (min-width: 768px) {
                    width: 190px;
                    height: 190px;
                }
                @media (min-width: 800px) {
                    width: 250px;
                    height: 250px;
                }
                @media (min-width: 1024px) {
                    width: 280px;
                    height: 280px;
                }
                @media (min-width: 1200px) {
                    width: 320px;
                    height: 320px;
                }
            }
            .ring-react {
                position: absolute;
            }
            .ring1 {
                position: absolute;
                animation: ring-spin-clockwise 34s linear infinite;
                animation-timing-function: linear;
            }
            .ring2 {
                position: absolute;
                animation: ring-spin-counter-clockwise 30s linear infinite;
                animation-timing-function: linear;
            }
            .ring3 {
                position: absolute;
                animation: ring-spin-clockwise 37s linear infinite;
                animation-timing-function: linear;
            }
            @keyframes ring-spin-clockwise {
                0% {
                    transform: scale(1) rotateZ(0);
                }
                100% {
                    transform: scale(1) rotateZ(360deg);
                }
            }
            @keyframes ring-spin-counter-clockwise {
                0% {
                    transform: scale(1) rotateZ(360deg);
                }
                100% {
                    transform: scale(1) rotateZ(0deg);
                }
            }
        }
        
        #gridPaperHolder {
            opacity: 0;
            position: absolute;
            width: 100%;
        }
        
        .pcover {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: absolute;
            height: 600px;
            z-index: 100;
        }
        
        .umbrella {
            position: absolute;
            top: 288px;
            left: -30px;
            img {
                width: 100px;
                height: 210px;
            }
            @media (min-width: 320px) {
                top: 248px;
                left: -68px;
                img {
                    width: 105px;
                    height: 200px;
                }
            }
            @media (min-width: 375px) {
                top: 270px;
                left: -70px;
                img {
                    width: 120px;
                    height: 220px;
                }
            }
            @media (min-width: 411px) {
                top: 226px;
                left: -80px;
                img {
                    width: 135px;
                    height: 270px;
                }
            }
            @media (min-width: 500px) {
                top: 175px;
                left: -60px;
                img {
                    width: 120px;
                    height: 250px;
                }
            }
            @media (min-width: 540px) {
                top: 180px;
                left: -60px;
                img {
                    width: 130px;
                    height: 250px;
                }
            }
            @media (min-width: 728px) {
                top: 140px;
                left: -60px;
                img {
                    width: 120px;
                    height: 242px;
                }
            }
            @media (min-width: 768px) {
                top: 190px;
                left: -60px;
                img {
                    width: 124px;
                    height: 250px;
                }
            }
            @media (min-width: 800px) {
                top: 180px;
                left: -70px;
                img {
                    width: 130px;
                    height: 260px;
                }
            }
            @media (min-width: 960px) {
                top: 150px;
                left: -60px;
                img {
                    width: 130px;
                    height: 260px;
                }
            }
            @media (min-width: 1024px) {
                top: 148px;
                left: -70px;
                img {
                    width: 153px;
                    height: 300px;
                }
            }
            /* iphone 5 horz */
            @media (min-width: 568px) and (max-height: 320px) {
                top: 100px;
                left: -58px;
                img {
                    width: 90px;
                    height: 175px;
                }
            }
            /* iphone 6/7/8 horz */
            @media (min-width: 667px) and (max-height: 375px) {
                top: 170px;
                left: -55px;
                img {
                    width: 90px;
                    height: 175px;
                }
            }
            /* iphone 6/7/8 plus horz */
            @media (min-width: 736px) and (max-height: 414px) {
                top: 100px;
                left: -55px;
            }
            /* pixel 2 horz  */
            @media (min-width: 731px) and (max-height:411px) {
                top: 170px;
                left: -55px;
                img {
                    width: 90px;
                    height: 175px;
                }
            }
            /* pixel 2 XL horz */
            @media (min-width: 823px) and (max-height:411px) {
                top: 112px;
                left: -55px;
                img {
                    width: 100px;
                    height: 190px;
                }
            }
            /* iphone X*/
            @media (min-width: 812px) and (max-height:375px) {
                top: 112px;
                left: -55px;
                img {
                    width: 100px;
                    height: 190px;
                }
            }
        }
        
        .palette {
            position: absolute;
            top: 50px;
            left: 500px;
        }
        
        #socials {
            text-align: center;
            padding-top: 5px;
            @media only screen and (min-width: 768px) {
                padding-right: 0px;
                padding-top: 10px;
                text-align: right;
            }
            .social-icon {
                max-width: 22px;
                margin: 6px;
                @media (min-width: 640px) {}
                @media (min-width: 768px) {
                    max-width: 18px;
                }
                @media (min-width: 960px) {}
                @media (min-width: 1024px) {
                    margin: 4px;
                }
            }
        }
        
        #tagline {
            text-align: center;
            padding-top: 5px;
            padding-left: 0px;
            @media (min-width: 768px) {
                text-align: left;
                padding-top: 10px;
                padding-left: 0px;
            }
        }
        
        .scroller-div {
            height: 4000px;
        }
        
        #devText {
            position: relative;
            opacity: 0;
            z-index: 100;
            width: 260px;
            left: 10px;
            top: 130px;
            margin: 5px;
            color: #FFF;
            padding: 10px;
            @media (min-width: 320px) {
                width: 220px;
                left: 100px;
                top: 180px;
            }
            @media (min-width: 345px) {
                width: 220px;
                left: 100px;
                top: 180px;
            }
            @media (min-width: 345px) {
                width: 220px;
                left: 100px;
                top: 180px;
            }
            @media (min-width: 375px) {
                width: 220px;
                left: 130px;
                top: 180px;
            }
            @media (min-width: 411px) {
                width: 280px;
                left: 130px;
                top: 170px;
            }
            @media (min-width: 640px) {
                left: 100px;
                top: 0px;
            }
            @media (min-width: 728px) {
                left: 90px;
                top: -30px;
            }
            @media (min-width: 768px) {
                left: 170px;
                top: -20px;
            }
            @media (min-width: 800px) {
                left: 176px;
                top: -80px;
            }
            @media (min-width: 1024px) {
                left: 220px;
                width: 380px;
                top: -70px;
            }
            /* iphone 5 horz*/
            @media (min-width: 568px) and (max-height: 320px) {
                width: 280px;
                left: 74px;
                top: -30px;
            }
            /* iphone 6/7/8 horz */
            @media (min-width: 667px) and (max-height: 375px) {
                width: 280px;
                left: 74px;
                top: -35px;
            }
            /* iphone 6/7/8 plus horz */
            @media (min-width: 736px) and (max-height: 414px) {
                width: 280px;
                left: 80px;
                top: -20px;
            }
            /* pixel 2 XL  */
            @media (min-width: 720px) and (max-height:411px) {
                width: 280px;
                left: 74px;
                top: -25px;
            }
            /* pixel 2 XL horz */
            @media (min-width: 823px) and (max-height:411px) {
                width: 280px;
                left: 74px;
                top: -70px;
            }
            /* iphone X*/
            @media (min-width: 812px) and (max-height:375px) {
                width: 280px;
                left: 92px;
                top: -90px;
            }
        }
        
        #devBlock {
            position: relative;
            opacity: .35;
            background-color: #e3e3e3;
            width: 50px;
            height: 50px;
            left: -50px;
            top: 40px;
            @media (min-width: 320px) {
                width: 50px;
                height: 80px;
                top: 130px;
            }
            @media (min-width: 345px) {
                width: 50px;
                height: 80px;
                top: 130px;
            }
            @media (min-width: 375px) {
                width: 50px;
                height: 90px;
                top: 130px;
            }
            @media (min-width: 411px) {
                width: 50px;
                height: 90px;
                top: 130px;
            }
            @media (min-width:640px) {
                width: 50px;
                height: 130px;
                top: 140px;
            }
            @media (min-width:728px) {
                width: 50px;
                height: 135px;
                top: 120px;
            }
            @media (min-width:800px) {
                width: 50px;
                height: 180px;
                top: 100px;
            }
            @media (min-width:1024px) {
                width: 50px;
                height: 190px;
                top: 100px;
            }
            /* iphone 5 horz */
            @media (min-width: 568px) and (max-height: 320px) {
                width: 50px;
                height: 122px;
                top: 98px;
            }
            /* iphone 6/7/8 horz */
            @media (min-width: 667px) and (max-height: 375px) {
                width: 50px;
                height: 122px;
                top: 98px;
            }
            /* pixel 2 horz */
            @media (min-width: 731px) and (max-height:411px) {
                width: 50px;
                height: 124px;
                top: 98px;
            }
            /* pixel 2 XL horz */
            @media (min-width: 823px) and (max-height:411px) {
                width: 50px;
                height: 122px;
                top: 55px;
            }
            /* iphone X*/
            @media (min-width: 812px) and (max-height:375px) {
                width: 50px;
                height: 140px;
                top: 40px;
            }
        }
        
        .character-icon {
            width: 50px;
            height: 50px;
            opacity: 0;
            margin: 0 auto;
            margin-top: 85px;
            text-align: center;
            img {
                width: 50px;
                height: auto;
            }
            @media(min-width: 640px) {
                margin-top: 70px;
            }
            @media(min-width: 800px) {
                margin-top: 40px;
                img {
                    width: 60px;
                    height: auto;
                }
            }
            /* iphone 5 horz */
            @media(max-width: 568px) and (max-height:320px) {
                margin-top: 70px;
                width: 45px;
                height: 25px;
                img {
                    width: 45px;
                    height: auto;
                }
            }
            /* iphone 6/7/8 horz */
            @media (max-width: 667px) and (max-height: 375px) {
                width: 45px;
                height: 25px;
                img {
                    width: 45px;
                    height: auto;
                }
            }
            /* pixel 2  horz */
            @media (max-width: 720px) and (max-height:411px) {
                width: 45px;
                height: 30px;
                img {
                    width: 45px;
                    height: auto;
                }
            }
            /* pixel 2 XL horz */
            @media (max-width: 823px) and (max-height:411px) {
                margin-top: 10px;
                img {
                    width: 52px;
                    height: auto;
                }
            }
            /* iphone X*/
            @media (max-width: 812px) and (max-height:375px) {
                padding-top: 0px;
                margin-top: 20px;
                width: 52px;
                height: 30px;
                img {
                    width: 52px;
                    height: auto;
                }
            }
        }
        
        .site-text {
            margin-left: 130px;
            margin-top: 5px;
            width: 280px;
            opacity: 0;
            @media(min-width: 320px) {
                margin-left: 60px;
                width: 260px;
            }
            @media(min-width: 375px) {
                margin-left: 60px;
                width: 260px;
            }
            @media(min-width: 411px) {
                margin-left: 70px;
                width: 300px;
            }
            @media(min-width: 420px) {
                margin-left: 40px;
                width: 300px;
            }
            @media(min-width: 421px) {
                margin-left: 70px;
                width: 350px;
            }
            @media(min-width: 500px) {
                margin-left: 90px;
                width: 380px;
            }
            @media(min-width: 640px) {
                margin-left: 80px;
                width: 420px;
            }
            @media(min-width: 728px) {
                margin-left: 80px;
                width: 450px;
            }
            @media(min-width: 800px) {
                margin-left: 120px;
                width: 500px;
            }
            @media(min-width: 1024px) {
                margin-top: 30px;
                margin-left: 15px;
                width: 220px;
            }
            @media(min-width: 1200px) {
                margin-top: 30px;
                margin-left: 10px;
                width: 270px;
            }
            /* iphone X vert */
            @media (max-width: 375px) and (max-height:812px) {
                margin-top: 20px;
                margin-left: 70px;
                width: 300px;
            }
            /* iphone5 horz */
            @media(max-width: 568px) and (max-height: 320px) {
                margin-top: 20px;
                margin-left: 80px;
                width: 480px;
            }
            /* iphone 6/7/8 horz */
            @media (max-width: 667px) and (max-height: 375px) {
                margin-top: 15px;
                margin-left: 90px;
                width: 440px;
            }
            /* iphone 6/7/8 PLUS horz */
            @media (max-width: 736px) and (max-height: 414px) {
                margin-top: 5px;
                margin-left: 100px;
                width: 430px;
            }
            /* pixel 2  horz */
            @media (max-width: 720px) and (max-height:411px) {
                margin-top: 20px;
                margin-left: 76px;
                width: 430px;
            }
            /* pixel 2 XL horz */
            @media (max-width: 823px) and (max-height:411px) {
                margin-top: 20px;
                margin-left: 150px;
                width: 430px;
            }
            @media(max-width: 1024px) and (max-height:768px) {
                /*  margin-top: 30px;
                margin-left: 15px;
                width: 220px;*/
            }
        }
        
        .cloud {
            width: 200px;
            height: 200px;
            content: " ";
            position: absolute;
            top: 10px;
            background-image: url("/img/colorwheel.png");
            background-size: cover;
        }
        
        #marketHolder {
            display: none;
            opacity: 0;
            z-index: -1;
            @media(min-width: 1024px) {
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 300px;
                height: 100%;
                background-image: url("/img/pike-market.jpg");
                background-size: cover;
                background-position: center bottom;
                background-repeat: no-repeat;
            }
        }
        
        #artistHolder {
            background-color: #931a25;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            @media(min-width: 1024px) {
                top: -30px;
                left: 200px;
                width: 240px;
            }
            @media(min-width: 1200px) {
                top: -30px;
                left: 200px;
                width: 310px;
            }
        }
        
        #scientistHolder {
            background-color: #4A6C6F;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            @media(min-width: 1024px) {
                position: absolute;
                top: -30px;
                left: 440px;
                width: 240px;
            }
            @media(min-width: 1200px) {
                top: -30px;
                left: 510px;
                width: 310px;
            }
        }
        
        #heroHolder {
            background-color: #2A1E5C;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            @media(min-width: 1024px) {
                top: -30px;
                left: 680px;
                width: 240px;
            }
            @media(min-width: 1200px) {
                top: -30px;
                left: 820px;
                width: 310px;
            }
        }