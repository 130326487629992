$deep-red: #ef4930;
$dark-gray: #231f20;
$light-gray: #d8d8d8;
$off-white: #f1f1f1;
$yellow: #d6a228;
$site-white: #ffffff;
$site-black: #181818;
$nav-highlight: #ffaf00;
body {
    background-color: $site-black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a:link {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color: $off-white;
}

a:active,
a:focus,
a:hover {
    color: #ffaf00;
    outline: 0;
    text-decoration: none;
}

a:visited {
    color: $off-white;
}

a.site-nav:link {
    text-decoration: none;
}

a.site-nav:active {
    text-decoration: none;
}

a.site-nav:hover {
    color: $nav-highlight;
    text-decoration: none;
}

a.site-nav:visited {
    text-decoration: none;
}

a.nav-active {
    &:link {
        color: $nav-highlight;
        text-decoration: none;
    }
    &:hover {
        color: $nav-highlight;
        text-decoration: none;
    }
    &:active {
        color: $nav-highlight;
        text-decoration: none;
    }
    &:visited {
        color: $nav-highlight;
        text-decoration: none;
    }
}

a.dark-text:link {
    color: $site-black;
    text-decoration: none;
}

a.dark-text:active {
    color: $nav-highlight;
    text-decoration: none;
}

a.dark-text:hover {
    color: $nav-highlight;
    text-decoration: none;
}

a.dark-text:visited {
    color: $site-black;
    text-decoration: none;
}

button.site-nav {
    text-decoration: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    background-color: $site-black;
    border-style: none;
    color: $site-white;
}

button.site-nav:hover {
    color: $nav-highlight;
}

.rel {
    position: relative;
}

.abs {
    position: absolute;
}

.block {
    display: block;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-display {
    display: flex;
}

.zero-opacity {
    opacity: 0;
}

.inline {
    display: inline;
}

.r-img {
    max-width: 100%;
    height: auto;
}

.pointer {
    cursor: pointer;
}

.pix-vspace-10 {
    height: 10px;
}

.pix-vspace-50 {
    height: 100px;
    background-color: #FFAF00;
}


/* COLOR */

.off-black-bg {
    background-color: $site-black;
}

.highlight {
    color: $nav-highlight;
}

.gray {
    background-color: $light-gray;
}

.light-bg {
    background-color: $off-white;
}

.dark-bg {
    background-color: $site-black;
}

.off-white {
    color: $off-white;
}

.site-white {
    color: $site-white;
}

.content-container {
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.black {
    color: #000;
}


/* TYPO */

.font-main {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.font-semi-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.normal-letter-space {
    letter-spacing: normal;
}

.font-bold {
    font-weight: bold;
}


/** PADDING */

.padded-1 {
    padding: 1em;
}

.padded-2 {
    padding: 2em;
}

.padtop-1 {
    padding-top: 1em;
}

.ital {
    font-style: italic;
}

.caps {
    text-transform: uppercase;
}

.site-text {
    font-size: 1em;
}

.bold-title {
    font-weight: bold;
}

.small-text {
    font-size: 0.85em;
}

.medium-text {
    font-size: 1em;
    font-weight: 600;
    @media screen and (min-width: 320px) {
        font-size: .9em;
    }
    @media screen and (min-width: 900px) {
        font-size: 1em;
    }
    @media screen and (min-width: 1200px) {
        font-size: 1.07em;
    }
}

.big-text {
    font-size: 10em;
}

.btn-primary {
    background-image: none !important;
    background-color: #ffaf00 !important;
    color: $site-black !important;
    padding: 0.3em;
}

.btn-primary:hover {
    background-color: $site-white !important;
}

.container {
    padding-left: 0;
    padding-right: 0;
}

.no-display {
    display: none;
}