@import './_SiteCore.scss';
.gallery {
    padding-top: 40px;
    #galGrid {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        @media (min-width: 900px) {
            justify-content: space-between;
            flex-direction: row;
        }
    }
    .galPreview {
        width: auto;
        height: 260px;
        text-align: center;
        @media (min-width: 560px) {
            height: 280px;
        }
        @media (min-width: 728px) {
            height: 240px;
        }
    }
    .previewRow {
        padding-top: 30px;
    }
    /*  &:hover>.galImageTop>.galAnimation {
            animation-play-state: running;
        }
        */
    .gal-icon {
        width: 100%;
        flex: 1;
        cursor: pointer;
        margin: 10px;
        &:hover {
            color: $nav-highlight;
        }
        &:hover>.gal-info>.site-nav {
            color: $nav-highlight;
        }
        @media (max-width: 728px) {
            text-align: center;
        }
        @media (min-width: 728px) {
            height: 260px;
            text-align: center;
        }
        img {
            height: 260px;
            width: 200px;
        }
    }
    .galImageTop {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 200px;
        height: 260px;
    }
    .galImgWrap img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .galAnimation {
        content: "";
        z-index: 10;
        position: absolute;
        height: 100%;
        width: 100%;
        transition: all 1s 0s;
        animation: shine 2s infinite;
        animation-play-state: paused;
    }
    @keyframes shine {
        0% {
            -webkit-filter: sepia(0) saturate(2);
        }
        50% {
            -webkit-filter: sepia(1) saturate(8);
        }
        100% {
            -webkit-filter: sepia(0) saturate(2);
        }
    }
    #jamBg {
        background-image: url("/img/jamstack-montage.png");
        height: 400px;
    }
    .gal-sidebar {
        background-color: #ffaf00;
    }
    .grid-icon {
        width: 20px;
        height: 20px;
        margin: 10px 2px 2px;
    }
}