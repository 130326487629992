@import './_SiteCore.scss';


.fade-in-and-out {
    opacity: 0;
    animation: fade-curve 1s linear forwards;
}

.fade-in {
    opacity: 0;
    animation: fade-in .4s linear forwards;
}

@keyframes fade-curve {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
