@import './_SiteCore.scss';
.work-holder {
    display: flex;
    justify-content: center;
    @media (min-width: 960px) {
        justify-content: flex-end;
    }
}

.work-content {
    margin: 0 auto;
    padding: 1.5em;
    @media (min-width: 640px) {
        max-width: 380px;
    }
    @media (min-width: 768px) {}
    @media (min-width: 960px) {
        max-width: 430px;
    }
    @media (min-width: 1024px) {
        max-width: 480px;
    }
}

.work-image {
    @media (min-width: 640px) {
        max-width: 380px;
    }
    @media (min-width: 768px) {
        max-width: 400px;
    }
    @media (min-width: 960px) {
        margin: 0 0 0 auto;
        max-width: 420px;
    }
    @media (min-width: 1024px) {
        max-width: 510px;
    }
}

.work-desc {
    text-align: left;
    max-width: 100%;
    padding: 0.5em 0.5em 0 1em;
    @media (min-width: 640px) {}
    @media (min-width: 768px) {}
    @media (min-width: 960px) {
        max-width: 400px;
    }
}

.work-desc-holder {
    padding-top: 1.5em;
    display: flex;
    justify-content: center;
    @media (min-width: 960px) {
        justify-content: flex-end;
    }
}

.work-header {
    margin-top: 0;
    padding-top: 96px;
    @media (min-width: 768px) {
        padding-top: 65px;
        padding-left: 35px;
    }
}

.search-filter {
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
        justify-content: flex-start;
    }
}

.work-page-container {
    background-color: $site-white;
    min-height: 1200px;
    height: 100%;
}

.btn.section-btn {
    padding-left: 1em;
    padding-right: 1em;
}

.btn.sub-group {
    padding-left: 1em;
    padding-right: 1em;
}

.btn.active {
    background-color: #fff !important;
}