@import './_SiteCore.scss';
.gallery-detail {
    padding-top: 80px;
    #backToGrid {
        text-align: right;
        cursor: pointer;
        @media (min-width: 420px) {
            text-align: left;
        }
    }
    .gal-arrow {
        border: solid #FFFFFF;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 5px;
    }
    .gal-arrow-right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .gal-nav-icon {
        cursor: pointer;
        display: inline;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        color: $site-white;
        width: 9px;
        height: 9px;
        margin: 5px;
        &:hover {
            color: #FFAF00;
        }
    }
    .gal-nav-icon-active {
        color: $nav-highlight;
        background-color: $nav-highlight;
        border-color: $nav-highlight;
    }
    .gallery__nav-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        opacity: 1;
    }
    .gal-cell.gal-info {
        font-size: 12px;
        margin-bottom: 5px;
        min-width: 100px;
        margin-left: 5px;
    }
    .gal-info {
        text-align: center;
    }
    .gal-btn {
        font-size: 12px;
        color: $site-white;
        margin-left: 5px;
        margin-right: 10px;
        cursor: pointer;
    }
    .gal-btn:hover {
        color: $nav-highlight;
        text-decoration: underline;
    }
    div.gal-cell {
        display: inline-flex;
    }
    .gal-cell.left {
        margin-top: 5px;
        align-items: right;
        cursor: pointer;
    }
    .gal-cell.right {
        margin-top: 5px;
        align-items: left;
        cursor: pointer;
    }
    .gal-icon {
        width: 100%;
        flex: 1;
        cursor: pointer;
        &:hover {
            color: $nav-highlight;
        }
        @media (max-width: 728px) {
            margin-top: 2em;
            margin-bottom: 2em;
            text-align: center;
        }
        @media (min-width: 728px) {
            margin-top: 2.4em;
            margin-bottom: 2em;
            max-height: 260px;
            text-align: center;
        }
    }
    .gal-image {
        justify-content: center;
        padding-top: 10px;
        img {
            max-width: 370px;
            padding: 15px;
            @media (min-width: 900px) {
                max-width: 412px;
                padding: 15px;
            }
        }
    }
    .gal-image-horz {
        justify-content: center;
        padding-top: 10px;
        img {
            max-width: 480px;
            padding: 10px;
            @media (min-width: 900px) {
                max-width: 600px;
                padding: 15px;
            }
        }
    }
    .iconStyle {
        font-size: 12.5rem;
        display: inline;
    }
    #galContent {
        /*  padding-top: 10px;*/
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .next-last {}
    .swipe-bar {
        min-height: 600px;
        background-color: #FFF;
        @media (min-width: 375px) {
            min-width: 20px;
        }
        @media (min-width: 411px) {
            min-width: 20px;
        }
        @media (min-width: 640px) {
            max-width: 30px;
        }
        @media (min-width: 728px) {
            max-width: 50px;
        }
        @media (min-width: 1024px) {
            max-width: 50px;
        }
    }
    .gallery__arrow {
        width: 13px;
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 12px;
    }
    .gallery__arrow-back {
        transform: scaleX(-1);
    }
    .gallery__nav {
        margin-top: 10px;
        height: 40px;
        @media (min-width: 1024px) {
            margin-top: 0.5em;
        }
    }
    .offstage-right {}
    .offstage-left {}
    .swipe-right {
        animation-name: slide-right;
        animation-duration: 0.7s;
    }
    @keyframes slide-left {
        from {
            padding-left: 0;
        }
        to {
            padding-left: 1500px;
        }
    }
    .swipe-left {
        animation-name: slide-left;
        animation-duration: 0.7s;
    }
    @keyframes slide-right {
        from {
            padding-right: 0;
        }
        to {
            padding-right: 1500px;
        }
    }
}